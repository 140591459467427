import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './image-slider.css';

const ImageSlider = (props) => {
  const [slideNumber, setSlideNumber] = useState(1);

  // Function to go to the next slide
  const nextSlide = () => {
    setSlideNumber((slideNumber % 5) + 1);
  };

  useEffect(() => {
    // Create a timer to change slides every 3 seconds
    const timer = setInterval(nextSlide, 3000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(timer);
  }, [slideNumber]);

  return (
    <div className={`image-slider-container ${props.rootClassName}`}>
      {slideNumber === 1 && (
        <div className="image-slider-slide1">
          <img
            alt={props.image_alt1}
            src={props.image_src1}
            className="image-slider-image"
          />
          <div onClick={() => setSlideNumber(5)} className="image-slider-left-button">
            <svg viewBox="0 0 1024 1024" className="image-slider-icon">
              <path
                d="M658 708l-60 60-256-256 256-256 60 60-196 196z"
                className=""
              ></path>
            </svg>
          </div>
          <div onClick={() => setSlideNumber(2)} className="image-slider-right-button">
            <svg viewBox="0 0 1024 1024" className="image-slider-icon02">
              <path
                d="M366 708l196-196-196-196 60-60 256 256-256 256z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}

      {slideNumber === 2 && (
        <div className="image-slider-slide2">
          <img
            alt={props.image_alt4}
            src={props.image_src4}
            className="image-slider-image1"
          />
          <div onClick={() => setSlideNumber(1)} className="image-slider-left-button1">
            <svg viewBox="0 0 1024 1024" className="image-slider-icon04">
              <path
                d="M658 708l-60 60-256-256 256-256 60 60-196 196z"
                className=""
              ></path>
            </svg>
          </div>
          <div onClick={() => setSlideNumber(3)} className="image-slider-right-button1">
            <svg viewBox="0 0 1024 1024" className="image-slider-icon06">
              <path
                d="M366 708l196-196-196-196 60-60 256 256-256 256z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}

      {slideNumber === 3 && (
        <div className="image-slider-slide3">
          <img
            alt={props.image_alt2}
            src={props.image_src2}
            className="image-slider-image2"
          />
          <div onClick={() => setSlideNumber(2)} className="image-slider-left-button2">
            <svg viewBox="0 0 1024 1024" className="image-slider-icon08">
              <path
                d="M658 708l-60 60-256-256 256-256 60 60-196 196z"
                className=""
              ></path>
            </svg>
          </div>
          <div onClick={() => setSlideNumber(4)} className="image-slider-right-button2">
            <svg viewBox="0 0 1024 1024" className="image-slider-icon10">
              <path
                d="M366 708l196-196-196-196 60-60 256 256-256 256z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}

      {slideNumber === 4 && (
        <div className="image-slider-slide4">
          <img
            alt={props.image_alt3}
            src={props.image_src3}
            className="image-slider-image3"
          />
          <div onClick={() => setSlideNumber(3)} className="image-slider-left-button3">
            <svg viewBox="0 0 1024 1024" className="image-slider-icon12">
              <path
                d="M658 708l-60 60-256-256 256-256 60 60-196 196z"
                className=""
              ></path>
            </svg>
          </div>
          <div onClick={() => setSlideNumber(5)} className="image-slider-right-button3">
            <svg viewBox="0 0 1024 1024" className="image-slider-icon14">
              <path
                d="M366 708l196-196-196-196 60-60 256 256-256 256z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}

      {slideNumber === 5 && (
        <div className="image-slider-slide5">
          <img
            alt={props.image_alt5}
            src={props.image_src5}
            className="image-slider-image4"
          />
          <div onClick={() => setSlideNumber(4)} className="image-slider-left-button4">
            <svg viewBox="0 0 1024 1024" className="image-slider-icon16">
              <path
                d="M658 708l-60 60-256-256 256-256 60 60-196 196z"
                className=""
              ></path>
            </svg>
          </div>
          <div onClick={() => setSlideNumber(1)} className="image-slider-right-button4">
            <svg viewBox="0 0 1024 1024" className="image-slider-icon18">
              <path
                d="M366 708l196-196-196-196 60-60 256 256-256 256z"
                className=""
              ></path>
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

ImageSlider.defaultProps = {
  image_alt1: 'image',
  image_alt2: 'image',
  image_alt3: 'image',
  image_alt4: 'image',
  image_alt5: 'image',
  image_src1: '/1-3000h.jpg',
  image_src2: '/2-3000h.jpg',
  image_src3: '/3-3000h.jpg',
  image_src4: '/4-3000h.jpg',
  image_src5: '/5-3000h.jpg',
  rootClassName: '',
};

ImageSlider.propTypes = {
  image_alt1: PropTypes.string,
  image_alt2: PropTypes.string,
  image_alt3: PropTypes.string,
  image_alt4: PropTypes.string,
  image_alt5: PropTypes.string,
  image_src1: PropTypes.string,
  image_src2: PropTypes.string,
  image_src3: PropTypes.string,
  image_src4: PropTypes.string,
  image_src5: PropTypes.string,
  rootClassName: PropTypes.string,
};

export default ImageSlider;
