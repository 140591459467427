import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import ImageSlider from '../components/image-slider';
import './home.css';

const Home = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Packages Concern</title>
        <meta property="og:title" content="Up Start Finance1" />
      </Helmet>
      <div className="home-navbar navbar-container">
        <div className="home-max-width max-width">
          <div className="home-logo">
            <a href="#home" className="home-link">
              <img alt="image" src="/logo.svg" className="home-image" />
            </a>
          </div>
          <div className={`home-links ${isMenuOpen ? 'show-menu' : ''}`}>
            <a href="#home" className="home-text navbarLink">
              Home
            </a>
            <a href="#service" className="home-link1 navbarLink">
              Service
            </a>
            <a href="#partners" className="home-link2 navbarLink">
              Partners
            </a>
            <a href="#about" className="home-link3 button-secondary button">
              About Us
            </a>
            <a href="#contact" className="home-link4 button button-primary">
              Contact Us
            </a>
          </div>
          <div className="home-burger-menu navbar-burger-menu" onClick={toggleMenu}>
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
            </svg>
          </div>
        </div>
      </div>
      <div id="home" className="home-hero hero-container section-container">
        <div className="home-max-width1 max-width">
          <div className="home-content">
            <h1 className="home-title">
              <span>
                Who
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text02">We Are</span>
            </h1>
            <span className="home-description">
              Welcome to Packages Concern, your one-stop solution for all your
              packaging needs. Whether you need corrugated boxes, custom
              packaging, or any other packaging product, we have you covered. We
              are a leading manufacturer and supplier of high-quality packaging
              solutions that are designed to suit your specific requirements. We
              have over 25 years of experience in the packaging industry, and we
              are proud to be ISO 9001 certified.
            </span>
          </div>
          <div className="home-image1">
            <img alt="image" src="/box-600w.gif" className="home-hero-image" />
          </div>
        </div>
      </div>
      <div className="home-section section-container">
        <div className="home-max-width2 max-width">
          <div className="home-content1">
            <h1 className="home-text03">
              <span className="home-text04">What</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text06">We Offer</span>
            </h1>
            <span className="home-text07">
              We offer competitive pricing, timely delivery, and excellent
              customer service. We are more than just a box company. We are a
              trusted partner that helps you achieve your business goals. We
              offer a wide range of products and services, from corrugated boxes
              to custom packaging solutions, that are both cost-effective and
              environmentally friendly.
            </span>
          </div>
          <img alt="image" src="/what-1200w.gif" className="home-hero-image1" />
        </div>
      </div>
      <div className="home-section1 section-container">
        <div className="home-max-width3 max-width">
          <div className="home-content2">
            <h1 className="home-text08">
              <span className="home-text09">
                How
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text10">We Work</span>
            </h1>
            <span className="home-text11">
              We have a state-of-the-art facility that can produce any type of
              packaging product, using high-quality materials and advanced
              machinery. We have a team of creative designers who can create
              custom packaging solutions that match your brand identity and
              product specifications. We have a fleet of vehicles and a network
              of logistics partners that can deliver your packaging product to
              your desired location in a timely manner. We have a dedicated
              customer support team that can assist you with any queries or
              issues related to your packaging product.
            </span>
          </div>
          <img alt="image" src="/who-1200w.gif" className="home-hero-image2" />
        </div>
      </div>
      <div className="home-section2 section-container">
        <div className="home-max-width4 max-width">
          <div className="home-content3">
            <h1 className="home-text12">
              <span className="home-text13">
                Why
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text14">Choose Us</span>
            </h1>
            <span className="home-text15">
              We are more than just a box company. We are a trusted partner that
              helps you change the way you think about packaging. With our
              innovative solutions and expert services, we help you reduce
              costs, improve efficiency, and protect your products. We have
              worked with some of the most reputed clients in various
              industries, such as food, beverage, pharmaceutical, textile,
              electronics, and more. You can check out our portfolio to see some
              of the projects that we have successfully completed.
            </span>
          </div>
          <img alt="image" src="/why-1200w.gif" className="home-hero-image3" />
        </div>
      </div>
      <div id="service" className="home-section3 section-container">
        <div className="home-max-width5 max-width">
          <div className="home-content4">
            <div className="home-content5">
              <h1 className="home-text16">Service</h1>
              <span className="home-text17">
                <span>
                  At Packages Concern, we offer a wide range of services to meet
                  your packaging needs. We can help you with:
                </span>
                <br></br>
                <br></br>
              </span>
              <div className="home-step">
                <div className="home-container1">
                  <span className="home-title1">Corrugated Rolls</span>
                  <span className="home-text21">
                    At PACKAGES CONCERN, we take pride in offering a
                    comprehensive range of high-quality corrugated rolls
                    designed to meet various packaging and shipping needs. As a
                    leading provider in the industry, we understand the
                    significance of reliable packaging solutions in safeguarding
                    your products during transit and storage.
                  </span>
                </div>
              </div>
              <div className="home-step1">
                <div className="home-container2">
                  <span className="home-title2">Shipper Corrugated Boxes</span>
                  <span className="home-text22">
                    At PACKAGES CONCERN, we specialize in providing high-quality
                    shipper corrugated boxes that ensure the safe and secure
                    transportation of your valuable goods. Our commitment lies
                    in offering sturdy, reliable packaging solutions that cater
                    to the diverse needs of businesses across various
                    industries.
                  </span>
                </div>
              </div>
              <div className="home-step2">
                <div className="home-container3">
                  <span className="home-title3">Retail Boxes</span>
                  <span className="home-text23">
                    <span>
                      Your go-to destination for high-quality retail boxes
                      designed to enhance your brand&apos;s packaging and
                      presentation. In the competitive world of retail, the
                      right packaging can make a significant difference. Our
                      retail boxes are not just containers; they are an
                      extension of your brand, offering both functionality and
                      aesthetic appeal.
                    </span>
                    <br></br>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="home-step3">
                <div className="home-container4">
                  <span className="home-title4">Printing Services</span>
                  <span className="home-text27">
                    <span>
                      At PACKAGES CONCERN, we pride ourselves on delivering
                      high-quality, professional printing services to meet your
                      diverse needs. Whether you&apos;re a small start-up, a
                      large corporation, an artist, or an individual looking for
                      personalized prints, our printing facility is equipped to
                      handle various projects efficiently and with the utmost
                      attention to detail.
                    </span>
                    <br></br>
                    <br></br>
                  </span>
                </div>
              </div>
              <div className="home-step4">
                <div className="home-container5">
                  <span className="home-title5">Matte Lamination</span>
                  <span className="home-text31">
                    <span>
                      At PACKAGES CONCERN, we understand the importance of
                      exceptional finishes for your printed materials. Our Matte
                      and shine Lamination Services offer both matte and shine
                      lamination services, catering to a variety of needs and
                      preferences. Whether you prefer the understated elegance
                      of matte or the vibrant allure of shine, we have you
                      covered.
                    </span>
                    <span className="home-text33"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <img alt="image" src="/serv-1200w.gif" className="home-hero-image4" />
        </div>
      </div>
      <div id="partners" className="home-testimonial">
        <ImageSlider rootClassName="image-slider-root-class-name"></ImageSlider>
      </div>
      <div id="about" className="home-section4 section-container">
        <div className="home-max-width6 max-width">
          <div className="home-content6">
            <h1 className="home-text34">
              <span className="home-text35">
                About
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text36">Us</span>
            </h1>
            <span className="home-text37">
              <span>
                Packages Concern is a leading manufacturer and supplier of
                high-quality packaging solutions that are designed to suit your
                specific requirements. We have over 25 years of experience in
                the packaging industry, and we are proud to be ISO 9001
                certified. We offer competitive pricing, timely delivery, and
                excellent customer service. We are more than just a box company.
                We are a trusted partner that helps you achieve your business
                goals.
              </span>
              <br></br>
              <br></br>
              <span>
                Our vision is to be the preferred choice for all your packaging
                needs, by providing innovative solutions and expert services
                that add value to your products and business.
              </span>
              <br></br>
              <br></br>
              <span>
                Our mission is to deliver high-quality packaging solutions that
                meet or exceed your expectations, by using the best materials,
                technology, and processes, and by adhering to the highest
                standards of quality, safety, and environmental responsibility.
              </span>
              <br></br>
              <br></br>
              <span>Our values are:</span>
              <br></br>
              <br></br>
              <span>
                &gt; Teamwork: We work together as a team, with respect, trust,
                and cooperation.
              </span>
              <br></br>
              <br></br>
              <span>
                &gt; Quality: We strive for excellence in everything we do, and
                we never compromise on quality.
              </span>
              <br></br>
              <br></br>
              <span>
                &gt; Customer Satisfaction: We listen to our customers,
                understand their needs, and exceed their expectations.
              </span>
              <br></br>
              <br></br>
              <span>
                &gt; Innovation: We embrace change, challenge ourselves, and
                seek new ways to improve our products and services.
              </span>
              <br></br>
              <br></br>
              <span>
                &gt; Integrity: We act with honesty, transparency, and
                accountability.
              </span>
            </span>
          </div>
          <img
            alt="image"
            src="/about-1200w.gif"
            className="home-hero-image5"
          />
        </div>
      </div>
      <div id="contact" className="home-section5 section-container">
        <div className="home-max-width7 max-width">
          <div className="home-content7">
            <h1 className="home-text63">
              <span className="home-text64">Contact</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text66">Us</span>
            </h1>
            <span className="home-text67">
              <span>
                We would love to hear from you. If you have any questions,
                feedback, or inquiries, please feel free to contact us using any
                of the following methods
              </span>
              <br></br>
              <br></br>
              <span>Phone 1: +92-322-9281187</span>
              <br></br>
              <span>Phone 2: +92-345-3424624</span>
              <br></br>
              <span>Phone 3: +92-321-9281121</span>
              <br></br>
              <br></br>
              <span>Email: info@packagesconcern.com</span>
              <br></br>
              <br></br>
              <span>
                Address: Plot WS/B-13, Block 02, Azizabad Industrial Area,
                Karachi, Sindh, Pakistan
              </span>
            </span>
            <div className="home-container6">
              <a
                href="https://api.whatsapp.com/send?phone=+923453424624"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link5"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon2">
                  <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
                </svg>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=+923229281187"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link6"
              >
                <svg viewBox="0 0 1024 1024" className="home-icon4">
                  <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
                </svg>
              </a>
            </div>
          </div>
          <img alt="image" src="/call-1200w.gif" className="home-hero-image6" />
        </div>
      </div>
      <footer className="home-footer">
        <a href="#home" className="home-link7">
          <img alt="logo" src="/logo.svg" className="home-image2" />
        </a>
        <span className="home-text82">
          © Packages Concern, All Rights Reserved.
        </span>
      </footer>
    </div>
  )
}

export default Home
